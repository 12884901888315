<template>
    <div class="row flex-center bg-light my-3">

        <div class="col-12 position-relative h-100 px-0 bg-custom top-radius-24 height-card" 
            :style="{ 'background-image': `url(${img})` }">
            <!-- <img :src="img" class="img-fluid w-100 top-radius-24"> -->

            <div class="linear flex-start-center pl-3 top-radius-24" 
                style="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px;">

                <span v-html="title" 
                    class="si--title-sm montserrat-bold text-white">
                </span>
            </div>

            <div class="flex-center" style="position: absolute;  top: 0px; right: 20px; bottom: 0px;">
                <div class="flex-center size-50" style="border-radius: 50%; border: 1px solid white;">
                    <img :src="(expanded) ? arrow_up : arrow_down" 
                        @click="expanded = !expanded"
                        class="size-48">
                </div>
            </div>
        </div>

        <!-- <div class="col-4 h-100 p-3 text-justify">
            <span v-html="description" 
                class="si--description-sm montserrat-bold text-white span-crop-4">
            </span>
        </div> -->

        <div class="col-12 border-danger text-justify py-3 bottom-radius-24" 
            style="border-top: 0px !important;" 
            v-if="expanded">
                <span v-html="description" 
                    class="si--description-sm montserrat-bold text-black">
                </span>
        </div>

        
        
    </div>
</template>

<script>
    //images
    import arrow_up from 'ASSETS/images/icon/arrow_up'
    import arrow_down from 'ASSETS/images/icon/arrow_down'
    
    export default {
        data() {
            return {
                arrow_up,
                arrow_down,
                expanded: false
            }
        },
        props: {
            img: {
                type: String,
                required: true,
                default: ''
            },
            title: {
                type: String,
                required: true,
                default: '',
            },
            description: {
                type: String,
                required: true,
                default: ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    .linear {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.68) 51.04%, rgba(217, 217, 217, 0) 100%);
        max-width: 250px;

        @include upPixels(600px) {
            max-width: 550px;
        }
    }

    .top-radius-24 {
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
    }

    .bottom-radius-24 {
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
    }

    .height-card {
        min-height: 70px;

        @include upPixels(600px) {
            min-height: 120px;
        }
    }

    .size-48 {
        width: 24px;
        height: 24px;

        @include upPixels(600px) {
            width: 48px;
            height: 48px;
        }
    }

    .size-50 {
        width: 26px;
        height: 26px;

        @include upPixels(600px) {
            width: 50px;
            height: 50px;
        }
    }

</style>
