<template>
    <div class="row w-100">
        <div class="col-12 p-0">
            <sp-separator :title="title" 
                :description="item[current_item].title"
                :variant="(variant == 1) ? 'gray' : 'danger'"
                scroll>
            </sp-separator>
        </div>

        <div class="col-12 p-0">
            <sp-slider-content :_id="_id" 
                :items="item"
                :autoplay="false"
                :show_dots="true"
                :variant="variant"
                @opennModal="showModal($event)"
                :show_next_item="show_next_item"
                :buttons_navigation="buttons_navigation">

            </sp-slider-content>


            <!-- <carousel id="a1da" :perPage="1" 
                :adjustableHeight="true"
                @page-change="handleSlideClick($event)"
                paginationColor="#CCCCCC"
                paginationActiveColor="#FF0000">

                <slide :data-index="index"
                    data-name="MySlideName"
                    
                    v-for="(value, index) in item" :key="index">
                    
                    <div class="row flex-center pt-5" style="min-height: 50vh">
                        <div style="min-height: 50vh"
                            class="col-12 col-md-6 flex-center-start h-100 py-5"
                            :class="`${(variant == 1) ? 'order-1' : 'order-2'}`">

                            <div style="max-width: 700px; width: 100%;">
                            
                                <div class="btn-pill text-center mb-4"
                                    :class="`${((index + 1) % 2 == 1) ? 
                                        ((variant == 1) ? 'bg-danger' : 'bg-gray') :
                                        ((variant == 1) ? 'bg-gray' : 'bg-danger') }`">

                                    <span class="montserrat-bold text-white" 
                                        style="font-size: clamp(22px, 3vw, 28px);">

                                        {{ value.title }}
                                    </span>
                                </div>

                                <div style="max-height: 40vh !important; overflow-y: scroll;">
                                    <span v-html="value.text">
                                    </span>    
                                </div>

                                <div class="d-flex justify-content-center pt-5" 
                                    v-if="value.gallery">

                                    <button @click.stop="showModal(value.gallery_title, 
                                            value.gallery_description, 
                                            value.gallery_images)"
                                            
                                        class="text-danger bg-white"
                                        style="width: 280px; height: 40px; border: 3px solid red; border-radius: 15px;">
                                        Más información
                                    </button>
                                </div>
                                
                            </div>

                        </div>
                        <div class="col-12 col-sm-10 col-md-6 h-100" 
                            style="min-height: 50vh"
                            :class="`${(variant == 1) ? 
                                'order-2 flex-center' : 
                                'order-1 flex-center'}`">

                            <--- <carousel id="asda" :perPage="1" 
                                :adjustableHeight="true"
                                :autoplay="true"
                                :loop="true">

                                <slide data-name="SlideInternal"
                                    :data-index="ci_index"
                                    v-for="(ci_value, ci_index) in value.carousel_internal" :key="ci_index">

                                    <img :src="ci_value"
                                        class="img-fluid">

                                </slide>
                            </carousel> --
                            <div style="max-width: 700px;" v-if="value.carousel_internal.length == 1">
                                <img :src="value.carousel_internal[0]" 
                                    class="img-fluid">    
                            </div>
                            
                            
                        </div>
                    </div>

                </slide>
              </carousel> -->
        </div>
    </div>
</template>

<script>
    import SPSeparator from 'COMPONENTS/Separator.vue'
    import { Carousel, Slide } from 'vue-carousel'

    export default {
        data() {
            return {
                current_item: 0,
                
            }
        },
        components: {
            'sp-separator': SPSeparator,
            Carousel,
            Slide
        },
        props: {
            _id: {
                type: String,
                required: true,
                default: '',
            },
            item: {
                type: Array,
                required: true,
                default: []
            },
            buttons_navigation: {
                type: Boolean,
                required: false,
                default: false
            },
            title: {
                type: String,
                required: false,
                default: ''
            },
            variant: {
                type: Number,
                required: false,
                default: 1
            },
            show_next_item: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        methods: {
            handleSlideClick(event) {
                this.current_item = event
            },
            showModal(event) {
                this.$emit('openModal', { 
                    title: event.title, 
                    description: event.gallery_description, 
                    gallery_images: JSON.stringify(event.gallery_images), 
                })
            }
        }
    }
</script>

<style lang="scss">
    .VueCarousel-dot-container {
        margin: 10px 0px !important;
    }
    .VueCarousel-dot, .VueCarousel-dot--active {
        margin: 5px 0px !important;
    }

   
</style>