import hotelery_0 from 'ASSETS/images/hotelery/0'
import hotelery_1 from 'ASSETS/images/hotelery/1'
import hotelery_2 from 'ASSETS/images/hotelery/2'
import hotelery_3 from 'ASSETS/images/hotelery/3'
import hotelery_4 from 'ASSETS/images/hotelery/4'

    // locker_parking
    import spg_lp_1 from 'ASSETS/images/spg_hotelery/locker_parking/1'
    import spg_lp_2 from 'ASSETS/images/spg_hotelery/locker_parking/2'
    import spg_lp_3 from 'ASSETS/images/spg_hotelery/locker_parking/3'

    // warehouse
    import spg_warehouse_1 from 'ASSETS/images/spg_hotelery/warehouse/1'
    import spg_warehouse_2 from 'ASSETS/images/spg_hotelery/warehouse/2'
    import spg_warehouse_3 from 'ASSETS/images/spg_hotelery/warehouse/3'
    import spg_warehouse_4 from 'ASSETS/images/spg_hotelery/warehouse/4'
    import spg_warehouse_5 from 'ASSETS/images/spg_hotelery/warehouse/5'

    // laundry
    import spg_laundry_1 from 'ASSETS/images/spg_hotelery/laundry/1'
    import spg_laundry_2 from 'ASSETS/images/spg_hotelery/laundry/2'
    import spg_laundry_3 from 'ASSETS/images/spg_hotelery/laundry/3'
    import spg_laundry_4 from 'ASSETS/images/spg_hotelery/laundry/4'
    import spg_laundry_5 from 'ASSETS/images/spg_hotelery/laundry/5'

    // waterproof
    import spg_waterproof_1 from 'ASSETS/images/spg_hotelery/waterproof/1'
    import spg_waterproof_2 from 'ASSETS/images/spg_hotelery/waterproof/2'
    import spg_waterproof_3 from 'ASSETS/images/spg_hotelery/waterproof/3'
    import spg_waterproof_4 from 'ASSETS/images/spg_hotelery/waterproof/4'
    import spg_waterproof_5 from 'ASSETS/images/spg_hotelery/waterproof/5'
    import spg_waterproof_6 from 'ASSETS/images/spg_hotelery/waterproof/6'
    import spg_waterproof_7 from 'ASSETS/images/spg_hotelery/waterproof/7'

    // quartz_system
    import spg_qs_1 from 'ASSETS/images/spg_hotelery/quartz_system/1'
    import spg_qs_2 from 'ASSETS/images/spg_hotelery/quartz_system/2'
    import spg_qs_3 from 'ASSETS/images/spg_hotelery/quartz_system/3'
    import spg_qs_4 from 'ASSETS/images/spg_hotelery/quartz_system/4'
    import spg_qs_5 from 'ASSETS/images/spg_hotelery/quartz_system/5'
    import spg_qs_6 from 'ASSETS/images/spg_hotelery/quartz_system/6'
    import spg_qs_7 from 'ASSETS/images/spg_hotelery/quartz_system/7'
    import spg_qs_8 from 'ASSETS/images/spg_hotelery/quartz_system/8'
    import spg_qs_9 from 'ASSETS/images/spg_hotelery/quartz_system/9'

    // pool_system
    import spg_ps_1 from 'ASSETS/images/spg_hotelery/pool_system/1'
    import spg_ps_2 from 'ASSETS/images/spg_hotelery/pool_system/2'
    import spg_ps_3 from 'ASSETS/images/spg_hotelery/pool_system/3'
    import spg_ps_4 from 'ASSETS/images/spg_hotelery/pool_system/4'
    import spg_ps_5 from 'ASSETS/images/spg_hotelery/pool_system/5'
    import spg_ps_6 from 'ASSETS/images/spg_hotelery/pool_system/6'
    import spg_ps_7 from 'ASSETS/images/spg_hotelery/pool_system/7'
    import spg_ps_8 from 'ASSETS/images/spg_hotelery/pool_system/8'
    import spg_ps_9 from 'ASSETS/images/spg_hotelery/pool_system/9'
    import spg_ps_10 from 'ASSETS/images/spg_hotelery/pool_system/10'

    // discharge
    import spg_discharge_1 from 'ASSETS/images/spg_hotelery/discharge/1'
    import spg_discharge_2 from 'ASSETS/images/spg_hotelery/discharge/2'
    import spg_discharge_3 from 'ASSETS/images/spg_hotelery/discharge/3'

export let hotelery = [
    { 
        title: "Servicios", 
        text: `
            <ul>
                <li>Albercas y jacuzzi.</li>
                <li>Área de osmosis.</li>
                <li>Cuarto de máquinas.</li>
                <li>Área de caldera.</li>
                <li>Cámaras frías.</li>
                <li>Almacén.</li>
                <li>Lavandería.</li>
                <li>Área de cocina.</li>
                <li>Área de lockers y regaderas.</li>
                <li>Membrana.</li>
                <li>Impermeabilizante.</li>
                <li>Lagos.</li>
                <li>Cenotes.</li>
                <li>Cisternas.</li>
                <li>Contenedores.</li>
                <li>Fuentes.</li>
                <li>Spa y áreas húmedas.</li>
                <li>Techumbres.</li>
            </ul>`,
        id_carosuel_internal: 'internal_hote_serv',
        carousel_internal: [
            hotelery_0,
            // hotelery_1,
            // hotelery_2,
            // hotelery_3, 
            // hotelery_4
        ],
        gallery: true,
        gallery_title: 'Galeria de hotelería',
        gallery_description: `

            <div class="row mb-3">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Hotelería
                    </span>
                </div>

                <div class="col-12">
                    Brindamos Soluciones para embellecer y mantener las instalaciones
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Cuarzo Alberca
                    </span>
                </div>
                <div class="col-12">
                    <span class="si--description-sm">
                        <ul>
                            <li>Sistema SimQuartz</li>
                            <li>Simcrete 250</li>
                        </ul> 

                        <span class="si--description-sm">
                            Ideal para emplearse como recubrimiento y mortero para conformación de albercas, jacuzzis, spas, fuentes de ornato, cenote, espejos de agua, tinas y regaderas <br><br>

                            Fácil Mantenimiento,  <br>
                            Cero uniones y juntas <br>
                            Curado Rápido. <br>
                            Variedad de tonos y combinación <br>
                        </span>
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Veneciano y juntas
                    </span>
                </div>
                <div class="col-12">
                    <span class="si--description-sm">
                        Variedad de colores y formatos, combinaciones adecuadas al ambiente <br><br>

                        Sistema Simflex 100 que restaura la junta desgastada en las albercas, resistente a los rayos UV, clarificadores, hipocloríto de sodio, alguicídas, ácido xclorhídrico, libre de solventes. Rápida Aplicación y curado. <br><br>
                    </span>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Pisos antiderrapantes en albercas y áreas comúnes
                    </span>
                </div>
                <div class="col-12">
                    <span class="si--description-sm">
                        Soluciona caídas por causa de piso resbaloso, 2 días de delimitación de áreas para aplicación de sistema de 6mm de espesor.  Diferentes colores y diseños.
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Pisos Epoxicos
                    </span>
                </div>
                <div class="col-12">
                    <span class="si--description-sm">
                        Sistema que protege las instalaciones de piso y cumple con las especificaciones de limpieza para un piso sanitario.
                    </span> <br>

                    <ul>
                        <li>Cocinas.</li>
                        <li>Cámaras Frías.</li>
                        <li>Subestaciones.</li>
                        <li>Motorlobby.</li>
                        <li>Calderas.</li>
                        <li>Rampas</li>
                        <li>Almacén</li>
                        <li>Área de descarga</li>
                        <li>Estacionamiento</li>
                    </ul>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Impermeabilizaciones
                    </span>
                </div>
                <div class="col-12">
                    <span class="si--description-sm">
                        Recubrimientos de silicona que se aplica en una sola mano y cura rápidamente para formar una membrana duradera, sin uniones, que es permeable al vapor de agua, impermeable al agua, impermeable y permanentemente flexible. Es estable a los rayos UV y mantiene su elasticidad incluso despuésés de décadas de exposición al tiempo y al clima. Se instala sobre todo tipo de techos. <br><br>

                        Membranas de TPO y PVC <br>

                        Acrílicos y Prefabricados.
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Cisternas, lagos, cenotes y fuentes
                    </span>
                </div>
                <div class="col-12">
                    <span class="si--description-sm">
                        Sistema epóxico que soluciona la perdida de agua por grietas y asentamientos. Garantiza la estanqueidad y un acabado sanitario para auditorías. 
                    </span>
                </div>
            </div>


        `,
        gallery_images: [
            {
                title: 'Sistema en Albercas',
                images: [
                    { img: spg_ps_1 },
                    { img: spg_ps_2 },
                    { img: spg_ps_3 },
                    { img: spg_ps_4 },
                    { img: spg_ps_5 },
                    { img: spg_ps_6 },
                    { img: spg_ps_7 },
                    { img: spg_ps_8 },
                    { img: spg_ps_9 },
                    { img: spg_ps_10 }
                ]
            },
            {
                title: 'Sistema de Cuarzo',
                images: [
                    { img: spg_qs_1 },
                    { img: spg_qs_2 },
                    { img: spg_qs_3 },
                    { img: spg_qs_4 },
                    { img: spg_qs_5 },
                    { img: spg_qs_6 },
                    { img: spg_qs_7 },
                    { img: spg_qs_8 },
                    { img: spg_qs_9 }
                ]
            },
            {
                title: 'Área de lockers y Estacionamiento',
                images: [
                    { img: spg_lp_1 },
                    { img: spg_lp_2 },
                    { img: spg_lp_3 }
                ]
            },
            {
                title: 'Cámara fría y almacén',
                images: [
                    { img: spg_warehouse_1 },
                    { img: spg_warehouse_2 },
                    { img: spg_warehouse_3 },
                    { img: spg_warehouse_4 },
                    { img: spg_warehouse_5 }
                ]
            },
            {
                title: 'Lavandería y Osmosis',
                images: [
                    { img: spg_laundry_1 },
                    { img: spg_laundry_2 },
                    { img: spg_laundry_3 },
                    { img: spg_laundry_4 },
                    { img: spg_laundry_5 }
                ]
            },
            {
                title: 'Membrana Impermeable',
                images: [
                    { img: spg_waterproof_1 },
                    { img: spg_waterproof_2 },
                    { img: spg_waterproof_3 },
                    { img: spg_waterproof_4 },
                    { img: spg_waterproof_5 },
                    { img: spg_waterproof_6 },
                    { img: spg_waterproof_7 }
                ]
            },
            {
                title: 'Subestacion y descarga',
                images: [
                    { img: spg_discharge_1 },
                    { img: spg_discharge_2 },
                    { img: spg_discharge_3 }
                ]
            },
        ]
    }
]