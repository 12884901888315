import certifications_1 from 'ASSETS/images/ingeniery/certifications/1'

import construction_supervision from 'ASSETS/images/ingeniery/construction_supervision/1'

import shop_construction_1 from 'ASSETS/images/ingeniery/shop_construction/1'
import shop_construction_2 from 'ASSETS/images/ingeniery/shop_construction/2'

export let ingeniery = [
    { 
        title: "Certificaciones", 
        text: `FDA Certificación de producto inspeccionado ( seguro, saludable, sanitarios y debidamente etiquetados ). <br><br>

                SQF ( Safe Quality Food ) productos aptos para inocuidad y calidad alimenticia. <br><br>

                NSF ( National Science Foundation ) productos certificados aprobados. <br><br>

                ISO 9001:2015 Norma Iso de gestión de calidad.`,
        id_carosuel_internal: 'internal_inge_cali',
        carousel_internal: [
            certifications_1
        ]
    }, 
    { 
        title: "Supervisión de Obra", 
        text: `Certificamos la supervisión de obra mediante un plan de trabajo con proyecto autorizado para garantizar una perfecta ejecución de los trabajos, certificando el proceso en calidad y seguridad. <br><br>

            Análisis técnico del proyecto ejecutivo con apertura de bitácora de obra como instrumento legal y de control. <br><br>

            Verificación de una adecuada ejecución de obra, números generadores, estimaciones, calificación de precios unitarios para obra extraordinaria, revisión, validación de estimaciones y finiquito. <br><br>

            Se realiza: <br>
            <ul>
                <li>Programación de juntas con contratistas para avance de obra.</li>
                <li>Elaboración de proyecto en área para certificar planos arquitectónicos, estéticos e isométricos.</li>
                <li>Bitácora de obra.</li>
                <li>Verificación de los alcances contratados.</li>
                <li>Actividades de información como es reporte de avance gerencial refiriéndose al avance de obra, aspectos de seguridad y fotografía.</li>
                <li>Supervisión de programas de ejecución y avance en campo.</li>
                <li>Apoyo para la puesta en operación.</li>
                <li>Elaboración de directorio de obra.</li>
                <li>Evaluación de obra diariamente.</li>
                <li>Supervisión de recursos, equipos y materiales.</li>
            </ul>`,
        id_carosuel_internal: 'internal_inge_supe',
        carousel_internal: [
            construction_supervision
        ]
    }, 
    { 
        title: "Shop Construction", 
        text: `Fundada por empresarios y respaldada por una sólida ética de trabajo y dedicación a la comunidad, creemos en la creación de asociaciones a largo plazo con nuestros clientes. <br><br>

                En los últimos 20 años, nuestro nombre se ha convertido en sinónimo de construcción experta, habiendo completado millones de m2 de nueva construcción y retail. <br><br>

                Nos enorgullecemos de capturar la visión de nuestros clientes y trabajar para superar sus expectativas. <br><br>

                Para nosotros Grupo Simepox estamos juntos con nuestros clientes, esto es mas que una simple frase. Así es cómo hacemos negocios.`,
        id_carosuel_internal: 'internal_inge_shop',
        carousel_internal: [
            shop_construction_1, 
            shop_construction_2
        ]
    }
]