<template>
    <div class="row w-100" style="height:120px; background-color: #222;">
        <div class="col-12 text-white d-block d-sm-flex align-items-center justify-content-start py-2 py-sm-0" 
            style="margin-left: -25px">

            <div data-aos="fade-right" 
                :class="`bg-${variant} flex-center mr-3`" 
                style="min-width: 300px; height: 60px; border-radius: 15px;">

                <span class="m-0 text-center montserrat-bold px-3" 
                    style=" font-size: clamp(22px, 3vw, 30px); ">
                    {{ title }}
                </span>
            </div>

            <div data-aos="fade-left" 
                class="flex-start-center mt-1 mt-sm-0 pt-2 pt-sm-0 pl-3 pl-sm-0">
                <span class="text-white montserrat-bold" 
                    style="font-size: clamp(20px, 3vw, 28px);">
                    {{  description }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                scroll_var: false,
            }
        },
        computed: {
            scroll_final() {
                return this.scroll_var = this.$props.scroll
            }
        },
        props: {
            variant: {
                type: String,
                required: false,
                default: 'danger'
            },
            title: {
                type: String,
                required: false,
                default: ''
            },
            description: {
                type: String,
                required: false,
                default: ''
            },
            scroll: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .scroll {
        animation-name: scroll;
        animation-iteration-count: infinite;
        animation-duration: 50s;
        animation-timing-function: linear;
        animation-delay: 5s;
    }

    @keyframes scroll {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-105%);
        }
    }
</style>