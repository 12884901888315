<template>
    <div id="sp-navbar" class="bg-dark position-custom" 
        style="margin-top: 48px; height: 60px; z-index: 5;">
        
        <nav class=" text-center d-flex justify-content-between align-items-center"
            style="max-width: 1750px">
            <div class="p-0 m-0 ml-5" style="max-width: 53px">
                <img :src="simepox_logo" class="img-fluid"/>
            </div>
            <span v-for="(item, index) in navbar_options" :key="index"
                class="text-white text-center d-none d-xl-block">
                <a :href="item.destination" class="text-white hover">
                    {{  item.label }}
                </a>
            </span>
            <span class="text-danger fw-bold d-none d-xl-block mr-5" style="font-size: 18px; color: #ff0000;">
                <b-icon icon="telephone-fill" variant="white" class="mr-2">
                </b-icon>
                <a href="tel:5543276724" style="color: #ff0000;">
                    55 4327 6724
                </a>
            </span>
            <b-icon @click.stop="toggleNavbar"
                class="d-block d-xl-none cursor-pointer mr-5"
                icon="justify" 
                variant="white"
                scale="2">
            </b-icon>
        </nav>

        <div class="position-relative" style="z-index: 1;">
            <nav v-if="toggle_menu"
                class="row bg-dark position-absolute" 
                :class="[animation_out ? 'out' : 'enter']"
                style="max-width: 250px; right: 0; top: 0px;">
                <div class="col-12 my-2" v-for="(item, index) in navbar_options" :key="index">
                    <a :href="item.destination" class="text-white hover">
                        {{ item.label  }}
                    </a>
                </div>
                <div class="col-12 my-2">
                    <span style="font-size: 18px; color: #ff0000;">
                        <b-icon icon="telephone-fill" variant="white" class="mr-2">
                        </b-icon>
                        <a href="tel:5543276724" style="color: #ff0000;">
                            55 4327 6724
                        </a>
                    </span>
                </div>
            </nav>
        </div>
        
    </div>
</template>

<script>
    // jQuery
    import $ from 'jquery'
    //images
    import simepox_logo from 'ASSETS/images/simepox_logo.png'

    export default {
        data() {
            return {
                simepox_logo,
                navbar_options: [
                    {
                        label: 'INICIO',
                        destination: '#inicio'
                    },
                    {
                        label: 'NUESTRA EMPRESA',
                        destination: '#nuestra_empresa'
                    },
                    {
                        label: 'SISTEMAS ESPECIALIZADOS',
                        destination: '#sistemas_especializados'
                    },
                    {
                        label: 'INGENIERÍA',
                        destination: '#ingenieria'
                    },
                    {
                        label: 'GALERÍA',
                        destination: '#galeria'
                    },
                    {
                        label: 'HOTELERÍA',
                        destination: '#hoteleria'
                    },
                    {
                        label: 'CONTACTO',
                        destination: '#contacto'
                    }
                ],
                toggle_menu: false,
                animation_out: false
            };
        },
        methods: {
            toggleNavbar() {
               if(!this.toggle_menu) {
                    this.animation_out = false
                    this.toggle_menu = true
               }

               else {
                    this.animation_out = true
                    setTimeout(() => {
                        this.toggle_menu = false
                    }, 1000)
               }
            }
        },
        mounted() {
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
                let nav = document.getElementById('sp-navbar')
                // let inicio = document.getElementById('inicio')
                // console.log(nav, 'nav')
                // console.log(inicio, 'inicio')
                // console.log(scroll, 'scroll')


                let device_width = document.documentElement.clientWidth
                // console.log(device_width, 'device_width')

                if(device_width < 992) {
                    nav.style.marginTop = "0px";
                    inicio.style.marginTop = "60px"

                    try {
                        nav.classList.remove("position-custom")
                        nav.classList.add("position-fixed")

                        // nav.classList.remove((scroll > 0) ? "position-block" : "position-fixed")
                        // nav.classList.add((scroll > 0) ? "position-fixed" : "position-block")
                    } catch(e) {}

                    // nav.style.position = (scroll > 48) ? "fixed" : "block";
                }
                else {
                    inicio.style.marginTop = "0px"
                    nav.classList.add("position-custom")
                    nav.style.marginTop = (scroll > 48) ? "0px" : `${(48 - scroll)}px`
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    #sp-navbar {
        width: 100vw;
    }

    .position-custom {
        @include downPixels(992px) {
            position: block;
            margin-top: 0px !important;
        }

        @include upPixels(992px) {
            position: fixed;
        }
    }

    .enter {
        animation-name: fade-in;
        animation-delay: 0s;
        animation-duration: 1s;
    }

    .hover:hover{
        color: red !important;
        text-decoration: none;;
    }

    .out {
        animation-name: fade-out;
        animation-delay: 0s;
        animation-duration: 1s;
    }

    @keyframes fade-in {
        0% {
            transform: translateX(120%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes fade-out {
        0% {
            transform: translateX(0);
            opacity: 1;
        }

        100% {
            transform: translateX(120%);
            opacity: 0;
        }
    }
</style>