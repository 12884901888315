import epoxic_floors_1 from 'ASSETS/images/especialized_systems/epoxic_floors/1'
import epoxic_floors_2 from 'ASSETS/images/especialized_systems/epoxic_floors/2'
import epoxic_floors_3 from 'ASSETS/images/especialized_systems/epoxic_floors/3'
import epoxic_floors_4 from 'ASSETS/images/especialized_systems/epoxic_floors/4'
import epoxic_floors_5 from 'ASSETS/images/especialized_systems/epoxic_floors/5'
import epoxic_floors_6 from 'ASSETS/images/especialized_systems/epoxic_floors/6'
    
    // storage
    import spg_storage_1 from 'ASSETS/images/sp_gallery/storage/1'
    import spg_storage_2 from 'ASSETS/images/sp_gallery/storage/2'
    import spg_storage_3 from 'ASSETS/images/sp_gallery/storage/3'
    import spg_storage_4 from 'ASSETS/images/sp_gallery/storage/4'
    import spg_storage_5 from 'ASSETS/images/sp_gallery/storage/5'

    // anti_skid
    import spg_anti_skid_1 from 'ASSETS/images/sp_gallery/anti_skid/1'
    import spg_anti_skid_2 from 'ASSETS/images/sp_gallery/anti_skid/2'
    import spg_anti_skid_3 from 'ASSETS/images/sp_gallery/anti_skid/3'
    import spg_anti_skid_4 from 'ASSETS/images/sp_gallery/anti_skid/4'
    import spg_anti_skid_5 from 'ASSETS/images/sp_gallery/anti_skid/5'
    import spg_anti_skid_6 from 'ASSETS/images/sp_gallery/anti_skid/6'
    import spg_anti_skid_7 from 'ASSETS/images/sp_gallery/anti_skid/7'

    // production_area
    import spg_pa_1 from 'ASSETS/images/sp_gallery/production_area/1'
    import spg_pa_2 from 'ASSETS/images/sp_gallery/production_area/2'
    import spg_pa_3 from 'ASSETS/images/sp_gallery/production_area/3'
    import spg_pa_4 from 'ASSETS/images/sp_gallery/production_area/4'
    import spg_pa_5 from 'ASSETS/images/sp_gallery/production_area/5'
    import spg_pa_6 from 'ASSETS/images/sp_gallery/production_area/6'

    // self_leveling
    import spg_sl_1 from 'ASSETS/images/sp_gallery/self_leveling/1'
    import spg_sl_2 from 'ASSETS/images/sp_gallery/self_leveling/2'
    import spg_sl_3 from 'ASSETS/images/sp_gallery/self_leveling/3'
    import spg_sl_4 from 'ASSETS/images/sp_gallery/self_leveling/4'
    import spg_sl_5 from 'ASSETS/images/sp_gallery/self_leveling/5'
    import spg_sl_6 from 'ASSETS/images/sp_gallery/self_leveling/6'
    import spg_sl_7 from 'ASSETS/images/sp_gallery/self_leveling/7'
    import spg_sl_8 from 'ASSETS/images/sp_gallery/self_leveling/8'
    import spg_sl_9 from 'ASSETS/images/sp_gallery/self_leveling/10'
    import spg_sl_10 from 'ASSETS/images/sp_gallery/self_leveling/11'

    // orange_peel
    import spg_op_1 from 'ASSETS/images/sp_gallery/orange_peel/1'
    import spg_op_2 from 'ASSETS/images/sp_gallery/orange_peel/2'
    import spg_op_3 from 'ASSETS/images/sp_gallery/orange_peel/3'
    import spg_op_4 from 'ASSETS/images/sp_gallery/orange_peel/4'
    import spg_op_5 from 'ASSETS/images/sp_gallery/orange_peel/5'
    import spg_op_6 from 'ASSETS/images/sp_gallery/orange_peel/6'
    import spg_op_7 from 'ASSETS/images/sp_gallery/orange_peel/7'
    import spg_op_8 from 'ASSETS/images/sp_gallery/orange_peel/8'
    import spg_op_9 from 'ASSETS/images/sp_gallery/orange_peel/9'
    import spg_op_10 from 'ASSETS/images/sp_gallery/orange_peel/10'
    import spg_op_11 from 'ASSETS/images/sp_gallery/orange_peel/11'

    // rooms
    import spg_rooms_1 from 'ASSETS/images/sp_gallery/rooms/1'
    import spg_rooms_2 from 'ASSETS/images/sp_gallery/rooms/2'
    import spg_rooms_3 from 'ASSETS/images/sp_gallery/rooms/3'
    import spg_rooms_4 from 'ASSETS/images/sp_gallery/rooms/4'
    import spg_rooms_5 from 'ASSETS/images/sp_gallery/rooms/5'
    import spg_rooms_6 from 'ASSETS/images/sp_gallery/rooms/6'
    import spg_rooms_7 from 'ASSETS/images/sp_gallery/rooms/7'
    import spg_rooms_8 from 'ASSETS/images/sp_gallery/rooms/8'

    // flakes_quartz
    import spg_fq_1 from 'ASSETS/images/sp_gallery/flakes_quartz/1'
    import spg_fq_2 from 'ASSETS/images/sp_gallery/flakes_quartz/2'
    import spg_fq_3 from 'ASSETS/images/sp_gallery/flakes_quartz/3'
    import spg_fq_4 from 'ASSETS/images/sp_gallery/flakes_quartz/4'
    import spg_fq_5 from 'ASSETS/images/sp_gallery/flakes_quartz/5'
    import spg_fq_6 from 'ASSETS/images/sp_gallery/flakes_quartz/6'
    import spg_fq_7 from 'ASSETS/images/sp_gallery/flakes_quartz/7'

    // epoxy_mortar
    import spg_em_1 from 'ASSETS/images/sp_gallery/epoxy_mortar/1'
    import spg_em_2 from 'ASSETS/images/sp_gallery/epoxy_mortar/2'
    import spg_em_3 from 'ASSETS/images/sp_gallery/epoxy_mortar/3'
    import spg_em_4 from 'ASSETS/images/sp_gallery/epoxy_mortar/4'
    import spg_em_5 from 'ASSETS/images/sp_gallery/epoxy_mortar/5'
    import spg_em_6 from 'ASSETS/images/sp_gallery/epoxy_mortar/6'
    import spg_em_7 from 'ASSETS/images/sp_gallery/epoxy_mortar/7'
    import spg_em_8 from 'ASSETS/images/sp_gallery/epoxy_mortar/8'

    // walkway
    import spg_walkway_1 from 'ASSETS/images/sp_gallery/walkway/1'
    import spg_walkway_2 from 'ASSETS/images/sp_gallery/walkway/2'
    import spg_walkway_3 from 'ASSETS/images/sp_gallery/walkway/3'
    import spg_walkway_4 from 'ASSETS/images/sp_gallery/walkway/4'
    import spg_walkway_5 from 'ASSETS/images/sp_gallery/walkway/5'
    import spg_walkway_6 from 'ASSETS/images/sp_gallery/walkway/6'
    import spg_walkway_7 from 'ASSETS/images/sp_gallery/walkway/7'
    import spg_walkway_8 from 'ASSETS/images/sp_gallery/walkway/8'

import epoxic_walls_1 from 'ASSETS/images/especialized_systems/epoxic_walls/1'
import epoxic_walls_2 from 'ASSETS/images/especialized_systems/epoxic_walls/2'
import epoxic_walls_3 from 'ASSETS/images/especialized_systems/epoxic_walls/3'
import epoxic_walls_4 from 'ASSETS/images/especialized_systems/epoxic_walls/4'
import epoxic_walls_5 from 'ASSETS/images/especialized_systems/epoxic_walls/5'
import epoxic_walls_6 from 'ASSETS/images/especialized_systems/epoxic_walls/6'

import internal_covering_0 from 'ASSETS/images/especialized_systems/internal_covering/0'
import internal_covering_1 from 'ASSETS/images/especialized_systems/internal_covering/1'
import internal_covering_2 from 'ASSETS/images/especialized_systems/internal_covering/2'
import internal_covering_3 from 'ASSETS/images/especialized_systems/internal_covering/3'
import internal_covering_4 from 'ASSETS/images/especialized_systems/internal_covering/4'
import internal_covering_5 from 'ASSETS/images/especialized_systems/internal_covering/5'
import internal_covering_6 from 'ASSETS/images/especialized_systems/internal_covering/6'

import layout_delimitation_1 from 'ASSETS/images/especialized_systems/layout_delimitation/1'
import layout_delimitation_2 from 'ASSETS/images/especialized_systems/layout_delimitation/2'
import layout_delimitation_3 from 'ASSETS/images/especialized_systems/layout_delimitation/3'
import layout_delimitation_4 from 'ASSETS/images/especialized_systems/layout_delimitation/4'
import layout_delimitation_5 from 'ASSETS/images/especialized_systems/layout_delimitation/5'
import layout_delimitation_6 from 'ASSETS/images/especialized_systems/layout_delimitation/6'

import concrete_joints_1 from 'ASSETS/images/especialized_systems/concrete_joints/1'
import concrete_joints_2 from 'ASSETS/images/especialized_systems/concrete_joints/2'
import concrete_joints_3 from 'ASSETS/images/especialized_systems/concrete_joints/3'
import concrete_joints_4 from 'ASSETS/images/especialized_systems/concrete_joints/4'
import concrete_joints_5 from 'ASSETS/images/especialized_systems/concrete_joints/5'

import design_and_fabrication_1 from 'ASSETS/images/especialized_systems/design_and_fabrication/1'
import design_and_fabrication_2 from 'ASSETS/images/especialized_systems/design_and_fabrication/2'
import design_and_fabrication_3 from 'ASSETS/images/especialized_systems/design_and_fabrication/3'
import design_and_fabrication_4 from 'ASSETS/images/especialized_systems/design_and_fabrication/4'
import design_and_fabrication_5 from 'ASSETS/images/especialized_systems/design_and_fabrication/5'
import design_and_fabrication_6 from 'ASSETS/images/especialized_systems/design_and_fabrication/6'

//polishing_buffing
import polishing_buffing_1 from 'ASSETS/images/especialized_systems/polishing_buffing/1'
import polishing_buffing_2 from 'ASSETS/images/especialized_systems/polishing_buffing/2'
import polishing_buffing_3 from 'ASSETS/images/especialized_systems/polishing_buffing/3'
import polishing_buffing_4 from 'ASSETS/images/especialized_systems/polishing_buffing/4'
import polishing_buffing_5 from 'ASSETS/images/especialized_systems/polishing_buffing/5'
import polishing_buffing_6 from 'ASSETS/images/especialized_systems/polishing_buffing/6'

// patching_kit
import patching_kit_1 from 'ASSETS/images/especialized_systems/patching_kit/1'
import patching_kit_2 from 'ASSETS/images/especialized_systems/patching_kit/2'
import patching_kit_3 from 'ASSETS/images/especialized_systems/patching_kit/3'
import patching_kit_4 from 'ASSETS/images/especialized_systems/patching_kit/4'

// restoration_cisterns
import restoration_cisterns_1 from 'ASSETS/images/especialized_systems/restoration_cisterns/1'
import restoration_cisterns_2 from 'ASSETS/images/especialized_systems/restoration_cisterns/2'
import restoration_cisterns_3 from 'ASSETS/images/especialized_systems/restoration_cisterns/3'
import restoration_cisterns_4 from 'ASSETS/images/especialized_systems/restoration_cisterns/4'
import restoration_cisterns_5 from 'ASSETS/images/especialized_systems/restoration_cisterns/5'

// waterproofing
import waterproofing_1 from 'ASSETS/images/especialized_systems/waterproofing/1'
import waterproofing_2 from 'ASSETS/images/especialized_systems/waterproofing/2'
import waterproofing_3 from 'ASSETS/images/especialized_systems/waterproofing/3'
import waterproofing_4 from 'ASSETS/images/especialized_systems/waterproofing/4'
import waterproofing_5 from 'ASSETS/images/especialized_systems/waterproofing/5'


export let especialized_systems = [
    { 
        title: "Pisos epóxicos", 
        text: `Los pisos epóxicos han revolucionado las condiciones de higiene, limpieza y resistencia que otros tipos de piso no proveen. <br>
            Nuestros sistemas de pisos están diseñados para satisfacer las últimas tendencias con uso extremo y cumplir con todas las normas y estándares, estos sistemas tienen como objetivo proteger la superficie de un piso de concreto, como elemento fundamental que sostiene toda edificación, contienen mezclas de silica, epoxi, uretano y sistema antihumedad para proporcionar beneficios específicos para cubrir cualquier necesidad de protección requerida en los giros comercial e industrial. 
        <br><br>`,
        id_carosuel_internal: 'internal_pisos_epox',
        carousel_internal: [
            epoxic_floors_6,
            epoxic_floors_1, 
            epoxic_floors_2, 
            epoxic_floors_3, 
            epoxic_floors_4, 
            epoxic_floors_5
        ],
        gallery: true,
        gallery_title: 'Galeria de pisos epóxicos',
        gallery_description: `
            <div class="row">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Pisos epóxicos
                    </span>
                </div>
            </div>

            Los pisos epóxicos han revolucionado las condiciones de higiene, limpieza y resistencia que otros tipos de piso no proveen. <br><br>

            Nuestros sistemas de pisos están diseñados para satisfacer las últimas tendencias con uso extremo y cumplir con todas las normas y estándares, estos sistemas tienen como objetivo proteger la superficie de un piso de concreto, como elemento fundamental que sostiene toda edificación, contienen mezclas de silica, epoxi, uretano y sistema antihumedad para proporcionar beneficios específicos para cubrir cualquier necesidad de protección requerida en los giros comercial e industrial. <br><br>

            

            <div class="row mb-3">
                <div class="col-12 col-sm-6 px-0">
                    <ul>
                        <li>Área Industrial</li>
                        <ul>
                            <li>Alimenticia.</li>
                            <li>Farmacéutica.</li>
                            <li>Automotriz.</li>
                            <li>Refresquera.</li>
                            <li>Química.</li>
                            <li>Petroquímica.</li>
                            <li>Metalmecánica.</li>
                            <li>Textil.</li>
                            <li>Siderúrgica.</li>

                            <li>Comunicaciones.</li>
                            <li>Electrónica.</li>
                            <li>Maquiladora.</li>
                            <li>Cosméticos.</li>
                        </ul>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 px-0">
                    <ul>
                        <li>Tiendas de autoservicio.</li>
                        <li>Centros comerciales.</li>
                        <li>Cámara fría y de congelación.</li>
                        <li>Almacenaje y distribución.</li>
                        <li>Pasillo peatonal y área de producción.</li>
                        <li>Helipuerto y hangares.</li>
                        <li>Manufactura.</li>
                        <li>Estacionamientos.</li>
                        <li>Aeroespacial.</li>
                        <li>Educación.</li>
                        <li>Subestaciones y cuarto de control.</li>
                        <li>Residencial.</li>
                        <li>Hotelería.</li>
                        <li>Generador de energía.</li>
                        <li>Deportiva.</li>
                    </ul>
                </div>    
            </div>

            <div class="row">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Beneficios de los pisos epoxicos
                    </span>
                </div>
                <div class="col-12">
                    <span class="si--description-sm">
                        Los beneficios de nuestro sistema epóxico son una consecuencia natural de la estructura química del recubrimiento, el cual mejora las propiedades mecánicas y químicas del concreto. <br><br>
                        Entre las numerosas ventajas de un piso epóxico destacamos las siguientes: <br>
                    </span>
                </div>
                <div class="col-12">
                    <ol class="si--description-sm">
                        <li>Resistencia al deslizamiento.</li>
                        <li>Resistencia al impacto.</li>
                        <li>Alta resistencia química.</li>
                        <li>Impermeabilidad.</li>
                        <li>Protección al concreto.</li>
                        <li>Antibacterial.</li>
                        <li>Flexible y elástico.</li>
                        <li>Acústico.</li>
                        <li>Altas y bajas temperaturas.</li>
                        <li>Fácil Mantenimiento.</li>
                        <li>Durabilidad.</li>
                        <li>Estética.</li>
                        <li>Piso seguro.</li>
                        <li>Monolítico (piso sin junta ni boquilla).</li>
                        <li>Reflexión de la luz.</li>
                        <li>Conductividad y control de estática.</li>
                    </ol>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-2">
                    <span class="si--title-sm">
                        Tipos de piso de alta resistencia
                    </span>
                </div>
                <div class="col-12 col-sm-6 px-0">
                    <ul class="si--description-sm">
                        <li>Autonivelantes</li>
                        <li>Antiderrapantes</li>
                        <li>Cascara de naranja</li>
                        <li>Mortero autonivelante</li>
                        <li>Cuarzos</li>
                        <li>Antiácidos</li>
                        <li>Decorativo</li>
                        <li>Conductivos</li>
                        <li>Antiestáticos</li>
                        <li>Disipativos</li>
                        <li>Hojuelados</li>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 px-0">
                    <ul>
                        <li>Ucrete</li>
                        <li>Monolíticos</li>
                        <li>Transparentes</li>
                        <li>Abrasivos</li>
                        <li>Epoxico con logo integrado</li>
                        <li>Lisos</li>
                        <li>Brillantes</li>
                        <li>3D</li>
                        <li>Mate</li>
                        <li>Porcelanato liquido</li>
                        <li>Microcemento</li>
                    </ul>
                </div>
            </div>

        `,
        gallery_images: [
            {
                title: 'Almacenaje',
                images: [
                    { img: spg_storage_1 },
                    { img: spg_storage_2 },
                    { img: spg_storage_3 },
                    { img: spg_storage_4 },
                    { img: spg_storage_5 }
                ]
            },
            {
                title: 'Antiderrapante',
                images: [
                    { img: spg_anti_skid_1 },
                    { img: spg_anti_skid_2 },
                    { img: spg_anti_skid_3 },
                    { img: spg_anti_skid_4 },
                    { img: spg_anti_skid_5 },
                    { img: spg_anti_skid_6 },
                    { img: spg_anti_skid_7 }
                ]
            },
            {
                title: 'Area de producción',
                images: [
                    { img: spg_pa_1 },
                    { img: spg_pa_2 },
                    { img: spg_pa_3 },
                    { img: spg_pa_4 },
                    { img: spg_pa_5 },
                    { img: spg_pa_6 }
                ]
            },
            {
                title: 'Autonivelante',
                images: [
                    { img: spg_sl_1 },
                    { img: spg_sl_2 },
                    { img: spg_sl_3 },
                    { img: spg_sl_4 },
                    { img: spg_sl_5 },
                    { img: spg_sl_6 },
                    { img: spg_sl_7 },
                    { img: spg_sl_8 },
                    // { img: spg_sl_9 },
                    // { img: spg_sl_10 },
                ]
            },
            {
                title: 'Cascara de naranja',
                images: [
                    { img: spg_op_1 },
                    { img: spg_op_2 },
                    { img: spg_op_3 },
                    { img: spg_op_4 },
                    { img: spg_op_5 },
                    { img: spg_op_6 },
                    { img: spg_op_7 },
                    { img: spg_op_8 },
                    // { img: spg_op_9 },
                    // { img: spg_op_10 },
                    // { img: spg_op_11 },
                ]
            },
            {
                title: 'Cuarto limpio, camara fria y subestación',
                images: [
                    { img: spg_rooms_1 },
                    { img: spg_rooms_2 },
                    { img: spg_rooms_3 },
                    { img: spg_rooms_4 },
                    { img: spg_rooms_5 },
                    { img: spg_rooms_6 },
                    { img: spg_rooms_7 },
                    { img: spg_rooms_8 }
                ]
            },
            {
                title: 'Hojuelas y cuarzos',
                images: [
                    { img: spg_fq_1 },
                    { img: spg_fq_2 },
                    { img: spg_fq_3 },
                    { img: spg_fq_4 },
                    { img: spg_fq_5 },
                    { img: spg_fq_6 },
                    { img: spg_fq_7 }
                ]
            },
            {
                title: 'Mortero epóxico',
                images: [
                    { img: spg_em_1 },
                    { img: spg_em_2 },
                    { img: spg_em_3 },
                    { img: spg_em_4 },
                    { img: spg_em_5 },
                    { img: spg_em_6 },
                    { img: spg_em_7 },
                    { img: spg_em_8 }
                ]
            },
            {
                title: 'Pasillo peatonal',
                images: [
                    { img: spg_walkway_1 },
                    { img: spg_walkway_2 },
                    { img: spg_walkway_3 },
                    { img: spg_walkway_4 },
                    { img: spg_walkway_5 },
                    { img: spg_walkway_6 },
                    { img: spg_walkway_7 },
                    { img: spg_walkway_8 }
                ]
            }
            
        ]
    }, 
    { 
        title: "Muros Epóxicos",
        text: `El sistema epóxico de 4 elementos para muros y plafones este fabricado con resinas epóxicas de alta calidad con alto contenido de sólidos, formando un recubrimiento continuo que evita la acumulación de polvo facilitando la limpieza, logrando muros lisos, completamente asépticos, de fácil limpieza, alta resistencia química a la sanitización diaria (libre de fenoles, sales, cuaternarias, germicidas ). <br>
            Se puede aplicar en colores matizados y en superficies de construcción tradicional, siempre logrando áreas asépticas
            ( clean rooms ) y estéticas. <br><br>

            Nuestro sistema tiene la gran ventaja de conformar un área integral, monolítica y sin juntas. Los productos utilizados son libres de solventes por lo que se podrá aplicar durante los procesos de producción ya que no se contamina la materia prima.
            Nuestro sistema proporciona una protección a largo plazo, facilitando la limpieza de su área, dándole un aspecto higiénico cumpliendo con las normas ambientales, de seguridad y sanidad. <br><br>

            Este sistema esta formulado para resistir: <br>
            Abrasión · Salpicaduras · Manchas · Líquidos · Elongación · Cambio de temperatura.`,
        id_carosuel_internal: 'internal_muros_epox',
        carousel_internal: [
            epoxic_walls_2, 
            epoxic_walls_1, 
            epoxic_walls_3, 
            epoxic_walls_4, 
            epoxic_walls_5,
            epoxic_walls_6
        ]
    }, 
    { 
        title: "Recubrimientos Industriales",
        text: `Los recubrimientos industriales son utilizados para obtener una protección en superficies, incrementando resistencia a la corrosión, desgaste, intemperie, así como los embates naturales. <br><br>

            Pueden ser aplicados en: <br>
            Muros · Columnas · Pisos · Estructura · Plataformas · Tanques · Cisternas · Maquinaria · Tubería · Cualquier superficie donde los embates naturales y corrosión del ambiente requieran un sistema eficaz y de alto desempeño. <br><br>

            Tipos de recubrimiento: <br>
            Epóxicos · Alquidalicos · Grado alimenticio (NSF,FDA) · Acrílicos Poliuretano · Vinílicos · Altas temperaturas · Retardante de fuego Esmalte epóxico · Luminiscente · Transparentes · Primarios Selladores. <br><br>

            La selección de un recubrimiento depende de varios factores; entre estos debemos considerar los siguientes: <br>

            <ul>
                <li>El tipo de sustrato / superficie a pintar ( ej. Acero al carbón ).</li>
                <li>Ambiente / exposición corrosiva a que este sujeto el sustrato ( ej. Vapores, humedad, etc. ).</li>
                <li>Tipo / estado del recubrimiento actual.</li>
                <li>Alternativas para preparar la superficie.</li>
                <li>Método de aplicación permitido.</li>
                <li>Temperatura y humedad en el medio ambiente.</li>
                <li>Espesor de película seca ( EPS ) adecuado.</li>
                <li>Requerimiento de secado y curado del recubrimiento.</li>
                <li>Presupuesto.</li>
                <li>Cantidad de sólidos / rendimiento del recubrimiento.</li>
            </ul>`,
        id_carosuel_internal: 'internal_recu_indu',
        carousel_internal: [
            internal_covering_0, 
            internal_covering_1, 
            internal_covering_2, 
            internal_covering_3, 
            internal_covering_4, 
            internal_covering_5,
            internal_covering_6
        ]
    }, 
    { 
        title: "Lay-out Delimitación de Áreas",
        text: `SEÑALIZACION DE LAY-OUT, sistema permanente para que sus áreas de producción, operaciones, e incluso estacionamientos, queden perfectamente señaladas. Los recubrimientos para señalización son de larga duración para evitar dar mantenimiento en corto plazo.`,
        id_carosuel_internal: 'internal_deli_area',
        carousel_internal: [
            layout_delimitation_1, 
            layout_delimitation_2, 
            layout_delimitation_3, 
            layout_delimitation_4, 
            layout_delimitation_5,
            layout_delimitation_6
        ]
    }, 
    { 
        title: "Restauración de Juntas de Concreto",
        text: `Como una alternativa de mantenimiento a sus instalaciones que permitirá las mejores condiciones de tránsito y durabilidad, reparándose grietas, juntas, nivelación y resistencia a la compresión e impacto. <br> 
            Sistema de dos componentes de resinas epóxica 100% solidas libres de solventes, diseñado para proteger el desgaste entre lozas y evitar accidentes, acumulación de mugre y un buen funcionamiento de áreas operativas, este sistema está diseñado para tener una excelente elasticidad y elongación, resuelve los problemas de juntas y diamantes en columna.`,
        id_carosuel_internal: 'internal_rest_junt',
        carousel_internal: [
            concrete_joints_1, 
            concrete_joints_2, 
            concrete_joints_3, 
            concrete_joints_4, 
            concrete_joints_5
        ]
    },
    { 
        title: "Diseño y Fabricación de Pisos de Concreto",
        text: `Empleamos las mejores técnicas, análisis, proyección y recomendación para el tipo de piso que usted necesita: <br>
            Hidráulico · Químico · Estampado · Ultradelgado · Brillado · Escobillado
            Lavado · Goteado · Estriado · Industrial · Ecológico · Oxidado · Con color · Rayado. 
            <br><br>

            Los pisos industriales son losas sobre terreno las cuales a su vez se encuentran clasificadas en: <br>
            <ul>
                <li>Concreto no reforzado (simple).</li>
                <li>Reforzada con barras o mallas para controlar el ancho de la fisura.</li>
                <li>Continuamente reforzadas.</li>
                <li>Losas de concreto de contracción compensada.</li>
                <li>Losas Postensadas.</li>
                <li>Losas de concreto con refuerzo de fibras de acero.</li>
                <li>Losas de concreto reforzado con fibras sintéticas.</li>
                <li>Losas estructurales reforzadas por requerimientos de código.</li>
            </ul> <br>
            
            Proponemos tener en cuenta las siguientes variables para el diseño de un concreto para piso industrial: <br>

            <ul>
                <li>Seguimiento de recomendaciones y parámetros.</li>
                <li>Definición del asentamiento.</li>
                <li>Tamaño máximo del agregado.</li>
                <li>Contenido de aire (usualmente debe estar entre 0 % y 2 %, se contempla solo el naturalmente atrapado. Para pisos expuestos a ciclos de congelamiento y deshielo se requiere un contenido de aire entre el 5 % y 8 %).</li>
                <li>Contenido de agua.</li>
                <li>Relación A/MC.</li>
                <li>Conformidad con Fcr (resistencia promedio requerida), f’c (resistencia de diseño), S (desviación estándar) y V (coeficiente de variación).</li>
                <li>Proporción de agregados.</li>
                <li>Ajuste por humedad.</li>
            </ul> <br>

            Además, dentro del control de calidad se realiza verificación constante de:  <br>

            <ul>
                <li>Temperatura. </li>
                <li>Asentamiento.</li>
                <li>Elaboración y curado de especímenes.</li>
                <li>Peso unitario y rendimiento volumétrico.</li>
                <li>Resistencia del concreto a la flexión.</li>
                <li>Contracción del concreto.</li>
            </ul>`,
        id_carosuel_internal: 'internal_dise_fabr',
        carousel_internal: [
            design_and_fabrication_1, 
            design_and_fabrication_2, 
            design_and_fabrication_3, 
            design_and_fabrication_4, 
            design_and_fabrication_5,
            design_and_fabrication_6
        ]
    },

    { 
        title: "Pulido y abrillantado de pisos",
        text: `El desbaste, pulido y abrillantado de piso es un proceso físico o químico que mejora la apariencia de los pisos obteniendo durabilidad, protección y resistencia. <br>
            Este proceso se utiliza para: <br><br>

            <ul>
                <li>Concreto.</li>
                <li>Mármol.</li>
                <li>Epoxico.</li>
                <li>Madera.</li>
                <li>Granito.</li>
                <li>Cuarzo</li>
                <li>Loseta.</li>
                <li>Mosaico.</li>
            </ul> 

            Este sistema evita cambiar e instalar un piso nuevo, se puede llevar a cabo este proceso para dar una actualización y mejora al piso existente. Se utiliza cuando el piso comienza a mostrar algún daño, grietas o nivelación y no cuenta con algún otro recubrimiento epóxico. Tener un piso pulido de concreto,  estará  mejor equipado para altos niveles de tráfico peatonal, de equipo y maquinaria, estando en excelente condición por más tiempo. <br><br>
            Ventajas:

            <ol>
                <li>Piso limpio y liso.</li>
                <li>Ambiente higiénico.</li>
                <li>Dar mas luz a las áreas.</li>
                <li>Preservar los elementos del piso.</li>
                <li>Alargar su durabilidad.</li>
                <li>Mantener las condiciones del suelo.</li>
                <li>Acabado estético.</li>
                <li>Nivelación de piso.</li>
            </ol>
        `,
        id_carosuel_internal: 'internal_puli_abri',
        carousel_internal: [
            polishing_buffing_1,
            polishing_buffing_2,
            polishing_buffing_3,
            polishing_buffing_4,
            polishing_buffing_5,
            polishing_buffing_6
        ]
    },
    { 
        title: "Kit de bacheo",
        text: `La aplicación del bacheo se realiza con resinas epóxicas de dos componentes de alta calidad, 100% sólidos, libre de solventes, con agregados de arena sílica,  permite regenerar losas de concreto dañado, fisuras, baches y grietas, es un sistema de rápido secado.`,
        id_carosuel_internal: 'internal_kit_bach',
        carousel_internal: [
            patching_kit_1,
            patching_kit_2,
            patching_kit_3,
            patching_kit_4
        ]
    }, 
    { 
        title: "Restauración de cisternas y contenedores",
        text: `El sistema de restauración SIMEPOX para CISTERNAS Y TANQUES DE ALMACENAMIENTO (de concreto, mortero y mampostería) está compuesto por recubrimientos epoxicos impermeables, de origen 100% sólidos y mineral, los cuales se integran monolíticamente dentro de la superficie porosa, ofreciendo así alta seguridad en impermeabilidad, elongación aprox del 300% y amplia seguridad en higiene,  no contamina y evita el surgimiento de hongos ó bacterias en dicha superficie. Los recubrimientos impermeabilizantes cementosos SIMEPOX no son tóxicos, no contaminan, no se oxidan y no se descomponen por lo que son reconocidos ampliamente como seguros en su manejo y aplicación, y considerados óptimos para permanecer en contacto con agua potable para consumo humano <br><br>
            El sistema utilizado es: <br>
            <ul>
                <li>Geomembrana elástica termo fusionada</li>
                <li>Pasta epoxica sanitaria ( monolítico )</li>
            </ul>`,
        id_carosuel_internal: 'internal_rest_cist',
        carousel_internal: [
            restoration_cisterns_1,
            restoration_cisterns_2,
            restoration_cisterns_3,
            restoration_cisterns_4,
            restoration_cisterns_5
        ]
    }, 
    { 
        title: "Impermeabilización",
        text: `Aplicación de membrana con geotextil, el más moderno sistema de impermeabilización de hasta 20 años de duración sobre cualquier superficie, para techumbres, almacenes, cisternas, tanques, industrias y complejos habitacionales este sistema evita las filtraciones que en muchas ocasiones los productos sólidos de tipo asfáltico ó acrílico no pueden cubrir en su totalidad debido a sus resistencias mecánicas. Este sistema supera los antiguos sistemas en durabilidad y costo beneficio; así mismo este producto es utilizado para el desarrollo de lagos artificiales y protección contra ácidos en tanques, cisternas y diques. <br><br>
            <i>Materiales 100% solidos epoxicos de alta durabilidad y Geomembrana.<i> <br><br>

            Tipos de Impermeabilizantes: <br>
            <ul>
                <li>Acrílicos.</li>
                <li>Asfalticos.</li>
                <li>Cementosos.</li>
                <li>Prefabricados.</li>
                <li>Ecológicos.</li>
                <li>Elastomericos.</li>
                <li>Geomembrana con geotextil.</li>
            </ul>`,
        id_carosuel_internal: 'internal_imper_cian',
        carousel_internal: [
            waterproofing_1,
            waterproofing_2,
            waterproofing_3,
            waterproofing_4,
            waterproofing_5
        ]
    }, 
]