<template>
    <div class="row p-0 m-0 w-100 text-center pt-5" style="background-color: #1a1a1a;">
        <div class="col-12 pt-5 pb-3" style="font-size: clamp(24px, 3vw, 40px);">
            <p class="text-white montserrat-bod">
                COMUNÍCATE HOY MISMO Y 
                <strong style="color: #ff0000" class="montserrat-black">
                    COMENCEMOS TU PROYECTO
                </strong>
            </p>
        </div>

        <div class="col-12 flex-center">
            
            <div class="row w-100" style="max-width: 1200px;">
            
                <div class="col-12 col-lg-7 d-flex justify-content-center justify-content-lg-end py-5">
                    <div class="w-100" style="background-color: #3F3F3F; border-radius: 10px; max-width: 760px;"
                        v-if="!sended_email">
                        <div class="row p-0">
                            <div class="col-12 d-flex justify-content-start mt-2 ml-2">
                                <img height="100"
                                    :src="simepox_logo"/>
                            </div>

                            <div class="col-12 py-1 py-sm-3">
                                <input v-model="form.name"
                                    class="form-control"
                                    type="text"
                                    placeholder="Nombre completo"
                                    />
                            </div>

                            <div class="col-12 col-sm-6 py-1 py-sm-3">
                                <input v-model="form.phone"
                                    class="form-control"
                                    type="tel"
                                    placeholder="Teléfono"
                                    />
                            </div>

                            <div class="col-12 col-sm-6 py-1 py-sm-3">
                                <input v-model="form.email"
                                    class="form-control"
                                    type="email"
                                    placeholder="Email"
                                    />
                            </div>

                            <div class="col-12 col-sm-6 py-1 py-sm-3">
                                <input v-model="form.city"
                                    class="form-control"
                                    type="text"
                                    placeholder="Ciudad"
                                    />
                            </div>

                            <div class="col-12 col-sm-6 py-1 py-sm-3">
                                <input v-model="form.federative_entity"
                                    class="form-control"
                                    type="text"
                                    placeholder="Estado"
                                    />
                            </div>

                            <div class="col-12 py-1 py-sm-3">
                                <textarea v-model="form.message"
                                    class="form-control" 
                                    style="height: 180px;"
                                    placeholder="Mensaje">
                                </textarea>
                            </div>
                            <div class="col-12 py-3">
                                <button @click="sendMail()"
                                    class="btn btn-danger text-white"
                                    style="width: 100%; max-width: 300px;"
                                    type="button">
                                    Enviar
                                </button>
                            </div>

                            <div class="col-12 text-center">
                                <p class="text-white montserrat-semibold">
                                    Al hacer "click en enviar" aceptas nuestros términos y condiciones de privacidad 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-center-center w-100 text-left p-0" v-else>
                        <div style="max-width: 400px;">
                            <h1 class="al--title-md text-danger">
                                <b>¡Gracias!</b>
                            </h1> <br>
                            <h4 class="text-white">
                                Nos pondremos en contacto contigo a la brevedad.
                            </h4>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-5 text-white text-left py-5 d-flex align-items-evenly">
                    <div class="row">
                        <div class="col-12">
                            <p class="m-0 montserrat-bold"  style="font-size: clamp(18px, 3vw, 24px);">
                                Dirección <br>
                            </p>
                            <p class="montserrat-regular" style="font-size: clamp(14px, 3vw, 20px);">
                                Calle Lago Garda Mz 3 Lt1 <br>
                                Bosque Esmeralda <br>
                                Atizapan de Zaragoza <br>
                                Estado de México, C.P 52930 <br>
                                
                            </p>
                        </div>

                        <div class="col-12">
                            <p class="m-0 montserrat-bold" style="font-size: clamp(18px, 3vw, 24px);">
                                Teléfono
                            </p>
                            <p class="montserrat-regular" style="font-size: clamp(14px, 3vw, 20px);">
                                55 4327 6724
                            </p>
                        </div>

                        <div class="col-12">
                            <p class="m-0 montserrat-bold" style="font-size: clamp(18px, 3vw, 24px);">
                                WhatsApp
                            </p>
                            <p class="montserrat-regular" style="font-size: clamp(14px, 3vw, 20px);">
                                55 4991 7455
                            </p>
                        </div>

                        <div class="col-12">
                            <p class="m-0 montserrat-bold" style="font-size: clamp(18px, 3vw, 24px);">
                                Correo
                            </p>
                            <p class="montserrat-regular" style="font-size: clamp(14px, 3vw, 20px);">
                                ventas@simepoxicos.com
                            </p>
                        </div>

                        <div class="col-12">
                            <p style="font-weight: bold; font-size: clamp(18px, 3vw, 24px);">
                                Síguenos
                            </p>
                            <a href="https://www.facebook.com/simepoxmexico" target="_blank">
                                <img :src="logos_FB"/>
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=+525549917455&text=Hola%20Simepox" target="_blank" class="pl-2">
                                <img :src="logos_wt"/>
                            </a>
                        </div>

                    </div>
                </div>

            </div>

        </div>

        <!--red footer-->
        <div class="col-12 bg-danger text-white montserrat-bold " style="min-height: 70px;">
            <div class="row justify-content-center align-items-center">
                <div class="col-12 col-xl-4 pt-4">
                    <p class="m-0">
                        2022. SIMEPOX. &copy; TODOS LOS DERECHOS RESERVADOS.
                    </p>
                </div>  
                <div class="col-12 col-sm-6 col-xl-4 pb-lg-4 pb-xl-0">
                    <p class="m-0 pt-4">
                        AVISO DE PRIVACIDAD
                    </p>
                </div>
                <div class="col-12 col-sm-6 col-xl-4 pb-3 pb-lg-4 pb-xl-0">
                    <p class="m-0 pt-4">
                        INFORMACIÓN DE CONTACTO
                    </p>
                </div>
            </div>
        </div>

        <div class="col-12 text-white text-center d-flex align-items-center justify-content-center montserrat-bold" style="min-height: 70px;">
            <p class="m-0">
                DESARROLLADO POR 
                <a class="text-white" href="https://monocilindrero.com" target="_blank">
                    MONOCILINDRERO.COM
                </a>
            </p>
        </div>

    </div>
</template>

<script>
    //images
    import simepox_logo from 'ASSETS/images/simepox_logo.png'
    //import social_logos from 'ASSETS/images/logos_redes.png'
    import logos_FB from 'ASSETS/images/logos_FB.png'
    import logos_wt from 'ASSETS/images/wt-logo.png'
    import messenger_logo from 'ASSETS/images/messenger_logo.png'

    export default {
        data() {
            return {
                simepox_logo,
                logos_FB,
                logos_wt,
                messenger_logo,
                form: {
                    name: null,
                    phone: null,
                    email: null,
                    message: null,
                    federative_entity: null,
                    city: null
                },
                sended_email: false
            }
        },
        methods:{
            sendMail() {
                this.sended_email = true
                
                fetch(`${CONFIG.server[CONFIG.env].page_url}/send_email.php`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json, text/plain, /',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        name: this.form.name, 
                        phone: this.form.phone, 
                        email: this.form.email,
                        federative_entity: this.form.federative_entity,
                        city: this.form.city,
                        message: this.form.message 
                    })
                }).then(res => res.json())
                .catch(error => console.error('Error:', error))
                .then(response => console.log('Success:', response))

                $(document).ready(function() {
                    $("body").children().each(function() {
                        $(this).html($(this).html().replace(/&#8232;/g," "))
                    })
                })
            }
        }
    }
</script>