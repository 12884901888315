import gallery_1 from 'ASSETS/images/gallery/1'
import gallery_2 from 'ASSETS/images/gallery/2'
import gallery_3 from 'ASSETS/images/gallery/3'
import gallery_4 from 'ASSETS/images/gallery/4'
import gallery_5 from 'ASSETS/images/gallery/5'
import gallery_6 from 'ASSETS/images/gallery/6'
import gallery_7 from 'ASSETS/images/gallery/7'
import gallery_8 from 'ASSETS/images/gallery/8'
import gallery_9 from 'ASSETS/images/gallery/9'
import gallery_10 from 'ASSETS/images/gallery/10'
import gallery_11 from 'ASSETS/images/gallery/11'
import gallery_12 from 'ASSETS/images/gallery/12'
import gallery_13 from 'ASSETS/images/gallery/13'
import gallery_14 from 'ASSETS/images/gallery/14'
import gallery_15 from 'ASSETS/images/gallery/15'
import gallery_16 from 'ASSETS/images/gallery/16'

export let galery = [
    gallery_1, 
    gallery_2, 
    gallery_3, 
    gallery_4,
    gallery_5, 
    gallery_6, 
    gallery_7, 
    gallery_8,
    gallery_9, 
    gallery_10, 
    gallery_11, 
    gallery_12,
    gallery_13, 
    gallery_14, 
    gallery_15, 
    gallery_16
]